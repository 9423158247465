import { textAlign } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    
    const [version, setVersion] = useState('');

    fetch('/.well-known/nmw-metadata').then(r => r.json()).then((metadata) => {
        setVersion('v' + metadata.majorVersion + '.' + metadata.minorVersion)
    }).catch(() => {
        setVersion('v0.0')
    })
  

    return (
        <div className={`visual-footer `}>
            <p style={{ textAlign: "center", marginBottom: "6px" }}>
                © {new Date().getFullYear()}
                <span
                    className="clickable"
                    onClick={() => { window.open("https://mynext.id/", "_blank") }}
                >
                    {" "}{t("Footer.brand")}{" "} {version} 
                </span>

            </p>
            <p style={{ textAlign: "center" }}>
                <span
                    className="clickable"
                    onClick={() => {
                        //navigate("/privacyPolicy");
                    }}
                >
                    {" "}{t("Footer.github-text")}{" "}
                    <a href="https://github.com/MyNextID/mDL-verifier/issues" target={"_blank"}>{t("Footer.github-link-title")}</a>.
                </span>
            </p>
        </div>
    );
};

export default Footer;