import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../App.css";
import { QRCodeSVG } from "qrcode.react";
import Footer from "../Footer/Footer";
import {} from "react-router-dom";
import Header from "../Header/Header";
import Navigation from "../Navigation/navigation";
import config from "../../config";

const shown: React.CSSProperties = {
  visibility: "visible",

  opacity: "1",
  transition: "0.01s",
};

const hidden: React.CSSProperties = {
  visibility: "hidden",
  opacity: "0",
  transition: "visibility 0s 2s, opacity 2s linear",
};

function LoginWith() {
  const location = useLocation();
  const [qrCode, setQrCode] = useState("");
  const [buttonLink, setButtonLink] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [received, setReceived] = useState(false);

  useEffect(() => {
    // Construct the URL with query parameters
    const url = "/api/v1/login_with" + location.search;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setQrCode(data.crossDevice);
        setButtonLink(data.sameDevice);
        setLoading(false);

        // check if we have a sessionId
        if (!data.sessionId) {
          setError("No sessionId received");
          return;
        }

        // create an eventsource to listen for the event
        let eventsource = new EventSource(
          config.server_url + `/events?id=${data.sessionId}`
        );

        eventsource.onmessage = function (event: MessageEvent) {
          // make sure it's not a ping message!
          if (event.data !== "ping") {
            let url = new URL(event.data);
            eventsource.close();
            window.location.href = url.href;
          }
        };

        eventsource.onerror = function (event: Event) {
          eventsource.close();
        };
      });
  }, []);

  const startTest = () => {
    setWaiting(true);
    window.location.href = buttonLink;
  };

  return (
    <div className="container">
      {error !== "" && <>ERROR: {error}</>}
      {error === "" && (
        <>
          {loading && <> Loading ... </>}
          {!loading && (
            <>
              {waiting ? (
                <>
                  {received && <>Processing shared credential</>}
                  {!received && <>Waiting for shared credential...</>}
                </>
              ) : (
                <>
                  <QRCodeSVG value={qrCode} height="200" width="200" />
                  <p className="selectTestType"> OR </p>
                  <button
                    id={"start-auth0"}
                    className="btn-primary"
                    onClick={() => {
                      startTest();
                    }}
                  >
                    Verify with MyNextID Wallet
                  </button>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default LoginWith;
