import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../img/logo_mynextID.svg";
import config from "../../config";
import LanguageSelector from "../LanguageSelector";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

function Navigation() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (cookieExists("nmw.session")) {
      setIsAuthenticated(true);
    }
  }, []);

  const cookieExists = (name: string): boolean => {
    // Split the document.cookie string into individual cookie pairs
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());

    // Loop through each cookie pair and check if it matches the provided name
    for (const cookie of cookies) {
      const [cookieName, _] = cookie.split("=");
      if (cookieName === name) {
        return true; // Cookie exists
      }
    }

    return false; // Cookie doesn't exist
  };

  return (
    <>
      <div className="navigation_bar">
        <img
          src={logo}
          alt=""
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="nav-links">
          {/* <QrCodeScannerIcon
            onClick={() => {
              navigate("/scanner");
            }}
          /> */}

          <nav id="basic-nav">
            <LanguageSelector />
          </nav>

          {/* {isAuthenticated ? (
            <button
              className="btn-primary-navigation"
              onClick={() => {
                let redirectUrl = config.auth0Logout;
                window.location.href = redirectUrl;
              }}
            >
              Logout
            </button>
          ) : (
            <button
              className="btn-primary-navigation"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
          )} */}
        </div>
      </div>
    </>
  );
}

export default Navigation;
