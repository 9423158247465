import React, { useEffect, useState } from "react";
import config from "../../config";

function Login() {
  function redirectToLogin() {
    let redirectUrl = config.auth0Login;
    window.location.href = redirectUrl;
  }

  return (
    <div className="login-container">
      <h2>Welcome to MyNextID Verifier</h2>
      <button onClick={redirectToLogin} className="btn btn-primary">
        Login
      </button>
    </div>
  );
}

export default Login;
