import React, { useState, useEffect, useCallback } from "react";
import {
  useSearchParams,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import config from "../../config";
import { Autocomplete, TextField } from "@mui/material";
import QrCodeScanner from "./qrCode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

function QrCodeReader() {
  //const navigate = useNavigate();

  const [openQrReader, setOpenQrReader] = useState(false);
  const [ticketStatus, setTicketStatus] = useState("");
  const [isTicketValid, setIsTicketValid] = useState(false);

  function handleQrClick() {
    setOpenQrReader(!openQrReader);
  }

  const onQrScan = async (result: string) => {
    const url = "http://localhost:8003/api/v1/tickets";
    fetch(url + "?ticket=" + result)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        validateTicket(data);
      });
  };

  function validateTicket(data: any) {
    /* console.log("data: ", data);
    console.log("validUntil: ", data.ticket.validUntil);
      console.log("new Date(): ", ); */

    if (data.error !== undefined && data.error !== "") {
      setTicketStatus(data.error);
    } else if (data == null) {
      setTicketStatus("Ticket not found!");
    } else if (data.ticket.validUntil < new Date().toISOString()) {      
      setTicketStatus("Ticket expired!");
    } else {
      setTicketStatus("Ticket is valid!");
      setIsTicketValid(true);
    }
  }

  return (
    <div>
      <QrCodeScanner qrBorderSize="100px" onScanResult={onQrScan} />
      {ticketStatus !== "" && <h2>Ticket Validation</h2>}
      {ticketStatus !== "" && isTicketValid && (
        <>
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ fontSize: "50px", color: "green" }}
          />
          <h3>{ticketStatus}</h3>
        </>
      )}
      {ticketStatus !== "" && !isTicketValid && (
        <>
          <FontAwesomeIcon
            icon={faCircleXmark}
            style={{ fontSize: "50px", color: "red" }}
          />
          <h3>{ticketStatus}</h3>
        </>
      )}
    </div>
  );
}

export default QrCodeReader;
