import React, { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useTranslation } from "react-i18next";

interface QrViewFinderProps {
  qrBorderSize: string;
  color?: string;
}

const url = window.location.origin;
console.log(url);

export function QrViewFinder(props: QrViewFinderProps): JSX.Element | null {
  if (props.qrBorderSize) {
    const color = props.color ? props.color : "white";
    return (
      <svg
        viewBox="0 0 100 100"
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          margin: "auto",
          zIndex: 100000,
          boxSizing: "border-box",
          border: `${props.qrBorderSize}px solid rgba(0, 0, 0, 0.0)`,
          position: "fixed",
          width: "100%",
          maxWidth: "480px",
        }}
      >
        <path fill="none" d="M23,0 L0,0 L0,23" stroke={color} strokeWidth="3" />
        <path
          fill="none"
          d="M0,77 L0,100 L23,100"
          stroke={color}
          strokeWidth="3"
        />
        <path
          fill="none"
          d="M77,100 L100,100 L100,77"
          stroke={color}
          strokeWidth="3"
        />
        <path
          fill="none"
          d="M100,23 L100,0 77,0"
          stroke={color}
          strokeWidth="3"
        />
      </svg>
    );
  }
  return null;
}

interface QrCodeScannerProps {
  onScanResult: (result: string) => void;
  qrBorderSize: string;
}

const QrCodeScanner: React.FC<QrCodeScannerProps> = (props) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [link, setLink] = useState("");

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={() => {
          setLink("");
          setOpened(!opened);
        }}
      >
        {link === "" ? "Scan QR code"  : "Rescan QR code" }
      </button>
      {opened && (
        <>
          <QrScanner
            tracker={false}
            onDecode={(result: string) => {
              setOpened(false);
              setLink(result);
              props.onScanResult(result);
            }}
            onError={(error: Error) => console.log(error?.message)}
            containerStyle={{
              position: "fixed",
              top: "0px",
              left: "0px",
              right: "0px",
              margin: "auto",
              width: "100%",
              height: "100vh",
            }}
            videoStyle={{
              position: "fixed",
              right: "0px",
              margin: "auto",
              width: "100%",
              height: "100vh",
              backgroundColor: "black",
            }}
            viewFinder={(x: any) => <QrViewFinder {...props} />}
          />
          <button
            className="btn btn-primary"
            style={{
              position: "fixed",
              bottom: "30px",
              left: "0px",
              right: "0px",
              margin: "auto",
              width: "240px",
            }}
            onClick={() => {
              setOpened(!opened);
            }}
          >
            {t("QrCodeScanner.cancel.btn")}
          </button>
        </>
      )}
      {/* {link !== "" && window.open(link) && <></>} */}
      {!opened}
    </>
  );
};

export default QrCodeScanner;
