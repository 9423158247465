import React from "react";
import { useState, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import config from "../../config";
import { Typography, Switch, FormGroup, Stack, TextField } from "@mui/material";

const shown: React.CSSProperties = {
    visibility: "visible",
  
    opacity: "1",
    transition: "0.01s",
  };
  
  const hidden: React.CSSProperties = {
    visibility: "hidden",
    opacity: "0",
    transition: "visibility 0s 2s, opacity 2s linear",
  };

function MDL() {
  const data = ["SCE_4VP_1", "SCE_4VP_2"];
  const [qrCode, setQrCode] = useState("");
  const [show, setShow] = useState(false);
  const [scenario, setScenario] = useState("");
  const [txId, setTxId] = useState("");
  const [output, setOutput] = useState<any>({
    SCE_4VP_1: "",
    SCE_4VP_2: "",
  });
  const [checked, setChecked] = useState(false);

  const handleChange = (event: any, value: any) => {
    if (value !== null) {
      setScenario(value);
      generateRandomTransactionID(16);
    } else {
      setScenario("");
      setTxId("");
      setQrCode("");
    }
  };

  useEffect(() => {
    //console.log("TxId changed to: " + txId);
    setUrl();
  }, [txId]);

  const startTest = () => {
    window.open(qrCode, "_blank");
  };

  const copyResults = (id: string) => {
    if (output[id] !== undefined) {
      navigator.clipboard.writeText(output[id]);
      setShow(true);
    }
  };

  function buildAuthRequestUrl(value: string) {
    setScenario(value);
    generateRandomTransactionID(16);
  }

  function setUrl() {
    let checkedQuery = "";
    if (checked) {
      checkedQuery = "&signed=true";
    }
    const url =
      "mdoc-openid4vp://?client_id=" +
      encodeURIComponent(config.client_id) +
      "&request_uri=" +
      encodeURIComponent(
        config.mdl_request_object_url +
          "?test=" +
          scenario +
          "&tx_id=" +
          txId +
          checkedQuery
      );

    //console.log(url);
    setQrCode(url);
  }

  const switchChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    //if (checked) {
    setUrl();
    //}
  }, [checked]);

  function generateRandomTransactionID(length: number) {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const idArray = [];

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      idArray.push(characters[randomIndex]);
    }

    setTxId(idArray.join(""));
  }

  useEffect(() => {
    if (txId !== "") {
      const eventSource = new EventSource(
        config.server_url + "/api/v1/sse?id=" + txId
      );
      //console.log("Opening event source for txId: " + txId);
      eventSource.onopen = () => {
        console.log("Connection to server opened.");
      };

      eventSource.onmessage = (event) => {
        // Handle the received event data
        console.log("Received:", event.data);
        let msg = JSON.parse(event.data).message;
        //console.log(msg);
        setOutput((oldOutput: any) => {
          return { ...oldOutput, [scenario]: oldOutput[scenario] + msg };
        });
      };

      eventSource.onerror = (error) => {
        console.error("Error:", error);
      };

      return () => {
        // Clean up the event source when component unmounts
        eventSource.close();
      };
    }
  }, [txId]);
  return (
    <div className="container">
      <div className="dropdown">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          className="autocomplete-dropdown"
          options={data}
          renderInput={(params: any) => (
            <TextField {...params} label="Select a test scenario" />
          )}
          onChange={handleChange}
          fullWidth={true}
        />
      </div>

      {scenario !== "" ? (
        <>
          <p>
            Start test scenario by choosing signed or unsigned Authorization
            Request Object:{" "}
          </p>
          <FormGroup style={{ padding: "20px", alignContent: "center" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Unsigned</Typography>
              <Switch
                checked={checked}
                onChange={switchChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Signed</Typography>
            </Stack>
          </FormGroup>
          <button
            id={"start-" + scenario}
            className="btn-primary"
            onClick={() => {
              startTest();
            }}
          >
            {scenario} test
          </button>
          <p className="selectTestType"> OR </p>
          <QRCodeSVG value={qrCode} height="160" width="160" />

          <div className="log-section">
            <p>Log output for {scenario}:</p>
            <textarea
              disabled
              id={"logs-" + scenario}
              value={output[scenario]}
            ></textarea>
            <br></br>
            <button
              className="btn-secondary"
              style={{ width: "100%" }}
              onClick={() => copyResults(scenario)}
            >
              Copy log
            </button>
            <span
              className="copy-text"
              style={show ? shown : hidden}
              onTransitionEnd={() => {
                setTimeout(() => {
                  setShow(false);
                }, 2000);
              }}
            >
              Copied to clipboard
            </span>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MDL;
