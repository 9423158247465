import { t } from "i18next";
import React from "react";
import "../../App.css";
import verifyIcon from "../../img/verify-icon-more.svg";
import { useTranslation } from "react-i18next";

function Header(props: any) {
  const { t } = useTranslation();

  return (
    <div className="section-title">
      <div className="logo-container">
        <img src={verifyIcon} alt="verifier-icon" className="verifier-icon" />
        <h1 className="navigation-title">{t("Header.title")}</h1>
      </div>
    </div>
  );
}

export default Header;
