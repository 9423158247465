import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/home";
import VerifierResults from "./components/VerifierResults/verifierResults";
import LoginWith from "./components/LoginWith/loginWith";
import MDL from "./components/MDL/mdl";
import Navigation from "./components/Navigation/navigation";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/login";
import QrCodeReader from "./components/QrCode/qrCodeReader";
import TicketsDemo from "./components/Tickets/tickets";

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/response_result" element={<VerifierResults />} />
          <Route path="/login_with" element={<LoginWith />} />
          <Route path="/mdl" element={<MDL />} />
          <Route path="/scanner" element={<QrCodeReader />} />
          <Route path="/tickets_demo" element={<TicketsDemo />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
