import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import config from "../../config";
import "../../App.css";
import logo from "../../img/logo_mynextID.svg";
import Footer from "../Footer/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import Navigation from "../Navigation/navigation";

import verifyIcon from "../../img/verify-icon-more.svg";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";

function VerifierResults() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [credentialSubject, setCredentialSubject] = useState<any>({});

  useEffect(() => {
    // get state from url
    const responseCode = searchParams.get("responseCode");

    // HTTP GET to get the credentialSubject
    fetch(
      config.server_url + "/api/v1/response_result?responseCode=" + responseCode
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data: ", data.credential_subject);
        setCredentialSubject(data.credential_subject);
      });
  }, []);

  const navigate = useNavigate();

  function formatKey(key: string) {
    return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ");
  }
  return (
    <div className="container">
      <div className="datalist-container">
        {credentialSubject ? (
          <>
            <h3>{t("Results.res-success")}</h3>
            <h3>
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ color: "#368737" }}
                size="3x"
              />
            </h3>
            <table className="table">
              <tbody>
                {Object.keys(credentialSubject).map((key, index) => {
                  if (typeof credentialSubject[key] === "object") {
                    return Object.keys(credentialSubject[key]).map((key2) => {
                      return (
                        <tr key={key2}>
                          <td className="col-1">{formatKey(key2)}</td>
                          <td className="col-2">
                            {credentialSubject[key][key2]}
                          </td>
                        </tr>
                      );
                    });
                  } else {
                    return (
                      <tr key={key}>
                        <td className="col-1">{formatKey(key)}</td>
                        <td className="col-2">{credentialSubject[key]}</td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <h3>{t("Results.res-error")}</h3>
            <h3>
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ color: "#C51E3A" }}
                size="3x"
              />
            </h3>
            <button
              className="btn-primary"
              onClick={() => {
                navigate("/");
              }}
            >
              {t("Results.start-btn")}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default VerifierResults;
