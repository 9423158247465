import React, { useState } from "react";
import config from "../../config";
import { blob } from "stream/consumers";

function TicketsDemo() {
  const [qrCodeData, setQRCodeData] = useState<string>("");
  const fetchQrCode = async () => {
    try {
      let url = config.ticketsApi;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: "Bearer qs0lti4v6j1bjv71",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: "development@netis.si",
          information: {
            eventName: "Event Name",
            eventLocation: "Event Location",
            eventDate: "2024-03-26T09:34:30Z",
            validFrom: "2024-03-19T09:34:30Z",
            validUntil: "2025-03-19T09:34:30Z",
          },
          qr: true,
        }),
      });
    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    const blobData = await response.blob();
    const objectUrl = URL.createObjectURL(blobData);
    setQRCodeData(objectUrl);
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };
  return (
    <div>
      <button className="btn-primary" onClick={fetchQrCode}>
        Fetch example Ticket
      </button>
      {qrCodeData && <img src={qrCodeData} />}
    </div>
  );
}

export default TicketsDemo;
