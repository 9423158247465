import React, { useEffect, useState } from "react";
import "../../App.css";
import { Autocomplete, TextField } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import config from "../../config";
import logo from "../../img/logo_mynextID.svg";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation/navigation";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";
import { use } from "i18next";

const shown: React.CSSProperties = {
  visibility: "visible",

  opacity: "1",
  transition: "0.01s",
};

const hidden: React.CSSProperties = {
  visibility: "hidden",
  opacity: "0",
  transition: "visibility 0s 2s, opacity 2s linear",
};

function Home() {
  const { t } = useTranslation();
  const data = config.credentialTypes;
  const [credentialTypes, setCredentialTypes] = useState(Object.values(data));
  const navigate = useNavigate();

  const [qrCode, setQrCode] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [scenario, setScenario] = useState("");
  const [output, setOutput] = useState();
  const [sessionId, setSessionId] = useState("");


  useEffect(() => {
    if (credentialTypes.length === 1) {
      const firstCredentialType = credentialTypes[0] as string; // Cast the value to string
      fetchQRCode(firstCredentialType);
    }
  }, [credentialTypes]);

  useEffect(() => {
    if (scenario !== "") {
      fetch(config.authRequestApi + "?type=" + getKeyByValue(data, scenario))
        .then((response) => response.json())
        .then((data) => {
          setQrCode(data.crossDevice);
          setButtonLink(`${data.sameDevice}&session_id=${data.sessionId}`);

          // SSE: sessionId is saved to sessionId variable here
          setSessionId(data.sessionId);

          let eventsource = new EventSource(
            config.server_url + `/events?id=${data.sessionId}`
          );

          eventsource.onmessage = function (event: MessageEvent) {
            // make sure it's not a ping message!
            if (event.data !== "ping") {
              let url = new URL(event.data);
              eventsource.close();
              navigate(
                `${url.pathname}?responseCode=${url.searchParams.get(
                  "responseCode"
                )}`
              );
            }
          };

          eventsource.onerror = function (event: Event) {
            eventsource.close();
          };
        });
    }
  }, [scenario]);

  useEffect(() => {
    // Set SSE channel here with sessionId
  }, [sessionId]);

  function getKeyByValue(
    object: { [key: string]: string },
    value: string
  ): string | undefined {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const handleChange = (event: any, value: any) => {
    fetchQRCode(value);
  };

  const fetchQRCode = (credentialType: string) => {
    if (credentialType !== null) {
      switch (credentialType) {
        case "MDL Credential":
          navigate("/mdl");
          break;
        case "Ticket Credential":
          navigate("/scanner");
          break;
      }
      setScenario(credentialType);
    } else {
      setScenario("");
      setQrCode("");
    }
  };

  return (
    <div className="container">
      {credentialTypes.length > 1 ? (
        <div className="dropdown">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            className="autocomplete-dropdown"
            options={credentialTypes}
            renderInput={(params: any) => (
              <TextField {...params} label={t("Home.select-placeholder")} />
            )}
            onChange={handleChange}
            fullWidth={true}
          />
        </div>
      ) : (
        <></>
      )}

      {scenario !== "" ? (
        <>
          <QRCodeSVG value={qrCode} height="200" width="200" />
          <p className="selectTestType"> {t("Home.or")} </p>
          <button
            id={"start-" + scenario}
            className="btn-primary"
            onClick={() => {
              window.open(buttonLink, "_blank");
            }}
          >
            {t("Home.verify-btn")} {scenario}
          </button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Home;
