// Config interface
export interface Config {
  server_url: string;
  authRequestApi: string;
  client_id: string;
  mdl_request_object_url: string;
  credentialTypes?: any;
  login_url: string;
  auth0Login?: string;
  auth0Logout?: string;
  ticketsApi?: string;
}

// Set types of credentials that can be requested - to show in the UI dropdown
const credentialTypes = {
  SommelierCredential: "Sommelier Credential",
  EmailCredential: "Email Credential",
  WorkCredential: "Work Credential",
  GinBrinAward: "Gin Brin Award Credential",
  DPPCredential: "DPP Credential",
  AuthorizedVerifierCredential: "Authorized Verifier Credential",
  EmployeeBadgeCredential: "Employee Ticket Credential",
  EventTicketCredential: "Event Ticket Credential",
  DriverLicenseCredential: "Driver's License Credential",
  CertificateOfAttendanceCredential: "Certificate of Attendance Credential",
  MDLCredential: "MDL Credential",
  TicketCredential: "Ticket Credential",
};

// Set up the configuration for different environments
// Production environment
const configProd: Config = {
  server_url: "https://verifier.mynext.id",
  authRequestApi: "https://verifier.mynext.id/api/v1/authorize",
  client_id: "https://verifier.mynext.id",
  mdl_request_object_url: "https://verifier.mynext.id/api/v1/mdl_authorization_request",
  credentialTypes: credentialTypes,
  login_url: "https://verifier.mynext.id/login",
  auth0Login: "https://verifier.mynext.id/auth/auth0/login",
  auth0Logout: "https://verifier.mynext.id/auth/auth0/logout",
  ticketsApi: "https://verifier.mynext.id/api/v1/tickets",
};

// Test environment
const configTest: Config = {
  server_url: "https://test-verifier.mynext.id",
  authRequestApi: "https://test-verifier.mynext.id/api/v1/authorize",
  client_id: "https://test-verifier.mynext.id",
  mdl_request_object_url: "https://test-verifier.mynext.id/api/v1/mdl_authorization_request",
  credentialTypes: credentialTypes,
  login_url: "https://test-verifier.mynext.id/login",
  auth0Login: "https://test-verifier.mynext.id/auth/auth0/login",
  auth0Logout: "https://test-verifier.mynext.id/auth/auth0/logout",
  ticketsApi: "https://test-verifier.mynext.id/api/v1/tickets",
};

// Local environment
const configLocal: Config = {
  server_url: window.location.origin,
  authRequestApi: window.location.origin + "/api/v1/authorize",
  client_id: "https://verifier.mynext.id",
  mdl_request_object_url: window.location.origin + "/api/v1/mdl_authorization_request",
  credentialTypes: credentialTypes,
  login_url: window.location.origin + "/login",
  auth0Login: window.location.origin + "/auth/auth0/login",
  auth0Logout: window.location.origin + "/auth/auth0/logout",
  ticketsApi: window.location.origin + "/api/v1/tickets",
};

const cfg: any = {
  "https://verifier.mynext.id": configProd,
  "https://test-verifier.mynext.id": configTest,
};
const url = window.location.origin;

export default cfg[url] ? cfg[url] : configLocal;
